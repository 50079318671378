module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CasterKit - Resources for Podcasters","short_name":"CasterKit","description":"Curated list of resources for podcasters. We're sharing what we've learned so you don't have to start at square one.","start_url":"/","background_color":"#f7f9fb","theme_color":"#5e17eb","display":"minimal-ui","icon":"static/logos/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"casterkit.com","customDomain":"stats.liist.io"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
